<template>
    <el-col :span="12">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clear_fix">
                <label>客户照片统计</label>
                <el-form style="float: right;padding-right: 20px;" size="mini" ref="form" :model="queryParams"
                         :inline="true">
                    <el-form-item>
                        <el-select placeholder="请选择仓库" v-model="queryParams.warId" style="width: 100px">
                            <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                                       :value="item.value"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="queryParams.companyId" clearable
                                   filterable remote reserve-keyword
                                   placeholder="企业名称"
                                   :remote-method="remoteMethod"
                                   :loading="searchCompanyLoading">
                            <el-option v-for="item in companyOption" :key="item.id" :label="item.companyName"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                                :clearable="false"
                                :editable="false"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                v-model="queryParams.localDates"
                                placeholder="请选择日期"
                                style="width: 200px"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="text" @click="getTableData" icon="el-icon-search" :loading="loading">查询</el-button>
                        <el-button type="text" plain icon="el-icon-download" size="mini" @click="exportExcel">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table :data="tableData" row-key="guid" :size="$store.getters.size" :show-header="true" height="460px"
                      v-loading="loading">
                <el-table-column type="index" :index="indexMethod" width="50" align="center"></el-table-column>
<!--                <el-table-column label="车号" prop="plat" width="88" align="center"></el-table-column>-->
                <el-table-column label="客户名称" prop="companyName"></el-table-column>
                <el-table-column label="地址" prop="address"></el-table-column>
                <el-table-column label="司机姓名" prop="realName"></el-table-column>
                <el-table-column label="照片数量" prop="num"></el-table-column>
            </el-table>
        </el-card>
    </el-col>
</template>

<script>
    import {getCompanyImgCount,exportExcelCompanyImgCount} from '@/api/system/driver'
    import {searchCompany} from '@/api/system/company'

    export default {
        name: "DriverImgCount",
        data() {
            return {
                queryParams: {
                    warId: undefined,
                    companyId: '',
                    localDates: [],
                },
                warehouseOption: this.$store.getters.warehouse,
                loading: false,
                tableData: [],
                exportLoading: false,
                companyOption: [],
                searchCompanyLoading: false,
            }
        },
        created() {
            let date = this.Date(new Date, 'YYYY-MM-DD')
            this.queryParams.localDates = [date, date]
        },
        mounted() {
            if (this.warehouseOption.length > 0) {
                this.queryParams.warId = this.warehouseOption[0].value;
                // this.getTableData();
            }
        },
        methods: {
            /** 导出 */
            exportExcel(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库");
                    return false;
                }
                if(this.exportLoading){return;}
                this.exportLoading = true
                exportExcelCompanyImgCount(this.queryParams).then((d)=>{this.exportLoading = false})
            },
            Date(date , format) {
                let year = date.getFullYear()
                let month = date.getMonth() + 1
                if (Number(month) < 10) {
                    month = '0' + month
                }
                let day = date.getDate()
                if (Number(day) < 10) {
                    day = '0' + day
                }
                let hour = date.getHours()
                if (Number(hour) < 10) {
                    hour = '0' + hour
                }
                let minute = date.getMinutes()
                if (Number(minute) < 10) {
                    minute = '0' + minute
                }
                let second = date.getSeconds()
                if (Number(second) < 10) {
                    second = '0' + second
                }
                const yearCode = format.substring(format.indexOf('YYYY') + 4, format.indexOf('YYYY') + 5)
                const monthCode = format.substring(format.indexOf('MM') + 2, format.indexOf('MM') + 3)
                return year + yearCode + month + monthCode + day
            },
            getTableData() {
                if (!this.queryParams.warId) {
                    this.$message.error("请选择仓库");
                    return false;
                }
                if (this.loading) {
                    return
                }
                this.loading = true
                // console.log(this.queryParams)
                getCompanyImgCount(this.queryParams).then(({code, data}) => {
                    this.loading = false
                    if (code === 0) {
                        this.tableData = data
                    }
                })
            },
            remoteMethod(query) {
                if (!this.queryParams.warId) {
                    this.$message.error("请选择仓库")
                    return false
                }
                if (!query) {
                    this.companyOption = []
                    return
                }
                let param = {warId: this.queryParams.warId, companyName: query}
                searchCompany(param).then(({code, data}) => {
                    if (code === 0) {
                        this.companyOption = data
                    }
                })
            },
            indexMethod(index) {
                return index + 1
            }
        }
    }
</script>

<style scoped>
    .css {

    }
</style>