<template>
    <el-col :span="12">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clear_fix">
                <label>司机轨迹统计</label>
                <el-form style="float: right;padding-right: 20px;" size="mini" ref="form" :model="queryParams"
                         :inline="true">
                    <el-form-item>
                        <el-select placeholder="请选择仓库" v-model="queryParams.warId" style="width: 100px">
                            <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                                       :value="item.value"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="queryParams.driverId" placeholder="请选择司机" style="width: 150px" clearable filterable>
                            <el-option v-for="(item, index) in drivers" :label="item.driverName" :value="item.guid"
                                       :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                                :clearable="false"
                                :editable="false"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                v-model="queryParams.localDate"
                                placeholder="请选择日期" style="width: 150px"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="text" @click="getTableData" icon="el-icon-search" :loading="loading">查询</el-button>
                        <el-button type="text" plain icon="el-icon-download" size="mini" @click="exportExcel">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table :data="tableData" row-key="guid" :size="$store.getters.size" :show-header="true" height="460px"
                      v-loading="loading">
                <el-table-column type="index" :index="indexMethod" width="50" align="center"></el-table-column>
                <el-table-column label="司机姓名" prop="realName"></el-table-column>
                <el-table-column label="车号" prop="plat"></el-table-column>
                <el-table-column label="车牌号" prop="vehicle"></el-table-column>
                <el-table-column label="轨迹点数" prop="num"></el-table-column>
            </el-table>
        </el-card>
    </el-col>
</template>

<script>
    import {trailCount,exportExceltrailCount} from '@/api/delivery/traffic_trail'
    import {getAllDriver} from '@/api/system/driver'

    export default {
        name: "DriverImgCount",
        data() {
            return {
                queryParams: {
                    warId: undefined,
                    driverId: '',
                    localDate: '',
                },
                warehouseOption: this.$store.getters.warehouse,
                loading: false,
                tableData: [],
                exportLoading: false,
                drivers: [],
            }
        },
        created() {
            let date = this.Date(new Date, 'yyyy-MM-dd')
            this.queryParams.localDate = date;
        },
        mounted() {
            if (this.warehouseOption.length > 0) {
                this.queryParams.warId = this.warehouseOption[0].value;
                // this.getTableData();
            }
        },
        methods: {
            /** 导出 */
            exportExcel(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库");
                    return false;
                }
                if(this.exportLoading){return;}
                this.exportLoading = true
                exportExceltrailCount(this.queryParams).then((d)=>{this.exportLoading = false})
            },
            Date(date, format) {
                let year = date.getFullYear()
                let month = date.getMonth() + 1
                if (Number(month) < 10) {
                    month = '0' + month
                }
                let day = date.getDate()
                if (Number(day) < 10) {
                    day = '0' + day
                }
                let hour = date.getHours()
                if (Number(hour) < 10) {
                    hour = '0' + hour
                }
                let minute = date.getMinutes()
                if (Number(minute) < 10) {
                    minute = '0' + minute
                }
                let second = date.getSeconds()
                if (Number(second) < 10) {
                    second = '0' + second
                }
                const yearCode = format.substring(format.indexOf('yyyy') + 4, format.indexOf('yyyy') + 5)
                const monthCode = format.substring(format.indexOf('MM') + 2, format.indexOf('MM') + 3)
                return year + yearCode + month + monthCode + day
            },
            getTableData() {
                if (!this.queryParams.warId) {
                    this.$message.error("请选择仓库");
                    return false;
                }
                if (this.loading) {
                    return
                }
                this.loading = true
                trailCount(this.queryParams).then(({code, data}) => {
                    this.loading = false
                    if (code === 0) {
                        this.tableData = data
                    }
                })
            },
            indexMethod(index) {
                return index + 1
            }
        },
        watch: {
            'queryParams.warId': function (val, oldVal) {
                if (val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.drivers = data;
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .css {

    }
</style>