<template>
	<div class="app-container">
		<!--<panel-group />-->
		<el-row :gutter="15">
			<punch-clock ref="punchClock"/>
			<delivery-overrun ref="deliveryOverrun" />
		</el-row>
		<el-row :gutter="15">
			<not-clock ref="notClock"/>
			<DriverInsure ref="DriverInsure"/>
		</el-row>
		<el-row :gutter="15">
			<DriverVehicle ref="DriverVehicle"/>
			<DriverImgCount ref="DriverImgCount"/>
		</el-row>
		<el-row :gutter="15">
			<CompanyImgCount ref="CompanyImgCount"/>
			<trailCount ref="trailCount"/>
		</el-row>
	</div>
</template>

<script>
	// import PanelGroup from "./components/PanelGroup";
	import PunchClock from "./components/PunchClock";
	import DeliveryOverrun from "./components/DeliveryOverrun";
	import NotClock from "./components/NotClock";
	import DriverInsure from "./components/DriverInsure";
	import DriverVehicle from "./components/DriverVehicle";
	import DriverImgCount from "./components/DriverImgCount";
	import CompanyImgCount from "./components/CompanyImgCount"
	import trailCount from  "./components/trailCount"

	export default {
		name: "Home",
		components: {
			//PanelGroup,
			PunchClock,
			DeliveryOverrun,
			NotClock,
			DriverVehicle,
			DriverInsure,
			DriverImgCount,
			CompanyImgCount,
			trailCount,
		},
		data() {
			return {
			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.home-content{

	}
</style>
