<template>
    <el-col :span="12">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clear_fix">
                <label>今日打卡迟到司机</label>
                <el-form style="float: right;padding-right: 20px;" size="mini" ref="form" :model="queryParams" :inline="true">
                    <el-form-item>
                        <el-select placeholder="请选择仓库" v-model="queryParams.warId">
                            <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="text" @click="getTableData" icon="el-icon-search" :loading="loading">查询</el-button>
                        <el-button type="text" plain icon="el-icon-download" size="mini" @click="exportExcel">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table :data="tableData" row-key="guid" :size="$store.getters.size" :show-header="true" height="460px" v-loading="loading">
                <el-table-column type="index" :index="indexMethod" width="50" align="center"></el-table-column>
                <el-table-column label="司机姓名" prop="driverName"></el-table-column>
                <el-table-column label="车号" prop="plat" width="88" align="center"></el-table-column>
                <el-table-column label="车牌号" prop="vehicle"></el-table-column>
                <el-table-column label="打卡时间" prop="createTime"></el-table-column>
            </el-table>
        </el-card>
    </el-col>
</template>

<script>
    import {driverClockOverrun,exportExcel} from '@/api/delivery/delivery'

    export default {
        name: "PunchClock",
        data(){
            return {
                queryParams: {
                    warId: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                tableData: [],
                exportLoading: false,
                loading: false
            }
        },
        mounted() {
            if(this.warehouseOption.length > 0){
                this.queryParams.warId = this.warehouseOption[0].value;
                // this.getTableData();
            }
        },
        methods: {
            getTableData(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库");
                    return false;
                }
                if(this.loading){
                    return
                }
                this.loading = true;
                driverClockOverrun(this.queryParams.warId).then(({code, data}) => {
                    this.loading = false;
                    if(code === 0) {
                        this.tableData = data;
                    }
                })
            },
            /** 导出 */
            exportExcel(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库");
                    return false;
                }
                if(this.exportLoading){return;}
                this.exportLoading = true
                exportExcel(this.queryParams).then((d)=>{this.exportLoading = false})
            },
            indexMethod(index){
                return index + 1;
            }
        }
    }
</script>

<style scoped>
    .clear_fix {
        line-height: 2;
    }
</style>
