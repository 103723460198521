<template>
    <el-col :span="12">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clear_fix">
                <label>车辆年检已过期和一个月内过期的司机</label>
                <el-form style="float: right;padding-right: 20px;" size="mini" ref="form" :model="queryParams" :inline="true">
                    <el-form-item>
                        <el-select placeholder="请选择仓库" v-model="queryParams.warId">
                            <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="text" @click="getTableData" icon="el-icon-search" :loading="loading">查询</el-button>
                        <el-button type="text" plain icon="el-icon-download" size="mini" @click="exportExcel">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table :data="tableData" row-key="guid" :size="$store.getters.size" :show-header="true" height="460px" v-loading="loading">
                <el-table-column type="index" :index="indexMethod" width="50" align="center"></el-table-column>
                <el-table-column label="司机姓名" prop="realName"></el-table-column>
                <el-table-column label="车号" prop="plat" width="88" align="center"></el-table-column>
                <el-table-column label="车牌号" prop="vehicle"></el-table-column>
                <el-table-column label="手机号" prop="phone"></el-table-column>
                <el-table-column label="年检到期时间" prop="vehicleInspection"></el-table-column>
            </el-table>
        </el-card>
    </el-col>
</template>

<script>
    import {getDriverVehicle,exportExcelDriverVehicle} from '@/api/system/driver'
    export default {
        name: "DriverVehicle",
        data() {
            return {
                queryParams: {
                    warId: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                loading: false,
                exportLoading: false,
                tableData: []
            }
        },
        mounted() {
            if(this.warehouseOption.length > 0){
                this.queryParams.warId = this.warehouseOption[0].value;
                // this.getTableData();
            }
        },
        methods: {
            /** 导出 */
            exportExcel(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库");
                    return false;
                }
                if(this.exportLoading){return;}
                this.exportLoading = true
                exportExcelDriverVehicle(this.queryParams).then((d)=>{this.exportLoading = false})
            },
            getTableData(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库");
                    return false;
                }
                if(this.loading){
                    return
                }
                this.loading = true
                getDriverVehicle(this.queryParams).then(({code, data}) => {
                    this.loading = false
                    if(code === 0){
                        this.tableData = data
                    }
                })
            },
            indexMethod(index){
                return index + 1
            }
        }
    }
</script>

<style scoped>
    .css{

    }
</style>